<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">{{ $t('general.asignacionpermisos') }}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-7">
                    <div class="input-group">                                                
                        <select v-model="Seleccionado" class="custom-select form-control-sm select">
                            <option>Super Administración</option>
                            <option>Administración</option>
                            <option>Gestión(Tramitador)</option>
                            <option>calidad</option>
                            <option>PAS</option>
                            <option>Facturación anterior</option>
                            <option>Comercial</option>
                            <option>Facturación</option>
                            <option>Multimanager</option>
                            <option>Plataforma</option>
                            <option>Contabilidad</option>
                            <option>Calidad telefonía</option>
                        </select>
                    </div>
                </div>
            <div class="col-md-1">
                <button @click="agregarPermiso(usuario.id,Seleccionado)" class="btn btn-primary">{{ $t('general.anadir') }}</button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-sm table-striped">
                    <tbody>
                        <tr v-for="permisos in permisos_usuario" :key="permisos.id_permiso">
                            <td>{{ permisos.nombre_permiso }}</td>
                            <td><button type="button" @click="eliminarPermiso(permisos.id_permiso)" class="btn btn-block btn-outline-danger btn-xs">{{ $t('general.eliminar') }}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario','id'],
    data() {
        return {
            Seleccionado: '',
            permisos_usuario:'',
        }
    },
    watch: {
        id() {
            this.cargardatos();
        },
    },
    methods: {
        async cargardatos(id) {
            /* get  modulos/pwgsapi/index.php/usuarios/:id/permisos */
            const api = new PwgsApi;
            const datos = await api.get('usuarios/' + id + '/permisos');
            this.permisos_usuario = datos.datos;
        },
        async eliminarPermiso(id_tipo_permiso) {
            /* delete  modulos/pwgsapi/index.php/usuarios/:id/permisos/:id_permiso */
            const api = new PwgsApi;
            await api.delete('usuarios/' + this.usuario.id + '/permisos/' + id_tipo_permiso);
            this.cargardatos(this.id);
        },
        async agregarPermiso(id,seleccion) {
            /* post  modulos/pwgsapi/index.php/usuarios/:id/permisos */
            let repetido = 0;            
            if (this.permisos_usuario.length > 1) {
                for (let pers of this.permisos_usuario) {   
                    if (seleccion == pers.nombre_permiso) {
                        repetido = repetido + 1;
                    }
                }
            }
            if (repetido < 1) {
                seleccion = { nombre_permiso: seleccion };
                const api = new PwgsApi;
                await api.post('usuarios/' + id + '/permisos', seleccion);
                this.cargardatos(this.id);  
            }
        },
    },
    mounted() {
        this.cargardatos(this.id);
    },

}
</script>
