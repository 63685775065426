<template>
   <!--<input type="text" class="form-control nombre_correo" v-model="remitente_predeterminado" placeholder="Nombre Correo">-->
    <div class="row">
        <div class="col-md-8">
            <seccion-datos :titulo="cuentasemail" @cambios="guardar(usuario.id)">
                   <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.usuariocorreo') }}</label>
                                <input type="text" v-model="usuario_mail_usuario" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.direccioncorreo') }}</label>
                                <input type="mail" v-model="direccion_mail_usuario" class="form-control nombre_completo dato" id="nombre_completo">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.clavecorreo') }}</label>
                                <input type="password" v-model="clave_mail_usuario" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                            </div>
    <div class="form-group">
        <label for="nombre_completo">{{ $t('general.servidorcorreo') }}</label>
        <input type="text" v-model="server_mail_usuario" class="form-control nombre_completo dato" id="nombre_completo">
    </div>
                        </div>
                    </div>
            </seccion-datos>
        </div></div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario'],
    data() {
        return {
            usuario_mail_usuario: '',
            clave_mail_usuario: '',
            server_mail_usuario: '',
            direccion_mail_usuario: '',
            remitente_predeterminado: '',
            id: '',
            cuentasemail: "Configuración de cuentas de email",
        }
    },
    methods: {
        cargardatos() {
            this.usuario_mail_usuario = this.usuario.usuario_mail_usuario;
            this.clave_mail_usuario = this.usuario.clave_mail_usuario;
            this.server_mail_usuario = this.usuario.server_mail_usuario;
            this.direccion_mail_usuario = this.usuario.direccion_mail_usuario;
            this.remitente_predeterminado = this.usuario.remitente_predeterminado;
            this.cuentasemail = this.$t('general.cuentasemail');
        },
        async guardar(id) {
            try {
                this.$store.dispatch('cambiarGuardando', true);
                const api = new PwgsApi;
                const subidadatos = { remitente_predeterminado: this.remitente_predeterminado, usuario_mail_usuario: this.usuario_mail_usuario, clave_mail_usuario: this.clave_mail_usuario, server_mail_usuario: this.server_mail_usuario, direccion_mail_usuario: this.direccion_mail_usuario, };
                await api.put('usuarios/' + id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
    },
    watch: {
        usuario() {
            this.cargardatos();
        }
    },
}
</script>